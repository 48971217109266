import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./common/Header";
import get_url from "./common/ApiCaller";
import axios from "axios";
import $ from "jquery";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
const Step1 = (props) => {
  const navigate = useNavigate();
  const [eventTypes, setEventTypes] = useState([]);
  const [radioButton, setRadioButton] = useState(false);
  const [updatedLists, setUpdatedLists] = useState("0");
  const [eventData, setEventData] = useState({});
  const [event, setEvent] = useState(0); // 1 for business and 2 for personal

  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("login_token")}`,
      event_id: localStorage.getItem("event_id"),
      user_role: localStorage.getItem("role"),
    },
  };
  const handleRadioButton = (item_id, index) => {
    $(`.planing_box1`).removeClass("active-border");
    $(`#div_id_${item_id}`).addClass("active-border");
    $(`.get_previos_name`).attr("checked", false);
    $(`#checkbox_id_${item_id}`).attr("checked", true);
    //$('.submit_button').prop("disabled", false);
  };
  const submitForm = () => {
    if (event == 0) {
      alert("Please choose either business or personal");
      return false;
    }

    let checked_radio_id = $('input[name="event_type"]:checked').val();

    if (checked_radio_id == undefined) {
      alert("Please select any event type first.");
      return false;
    } else {
      let event_data = {
        event_type: checked_radio_id,
        event: event,
        event_id: localStorage.getItem("event_id"),
      };
      axios
        .post(`${get_url.local_url}event-all-form`, event_data, header)
        .then((response) => {
          //console.log('response',response)
          if (localStorage.getItem("event_id") === null) {
            localStorage.setItem("event_id", response.data._id);
          }
          navigate("/step2");
        });
    }
  };
  const fetchEventType = async () => {
    await axios.get(`${get_url.local_url}event-types`).then((response) => {
      const get_radio_data = response.data.data;
      setEventTypes(get_radio_data);
    });
  };
  const fetchEventData = async () => {
    let event_id = localStorage.getItem("event_id");
    if ("undefined" !== typeof event_id) {
      await axios
        .get(`${get_url.local_url}event-data/${event_id}`)
        .then((response) => {
          const get_event_data = response.data[0];
          console.log(get_event_data);

          setEvent(get_event_data.event_type);
          setEventData(get_event_data);
          // if(get_event_data != undefined){
          //     handleRadioButton(get_event_data.type_of_event,0)
          // }
          setTimeout(function () {
            if ("undefined" !== typeof get_event_data.event_type_data._id) {
              console.log(typeof get_event_data.event_type_data._id);
              $(`#checkbox_id_${get_event_data.event_type_data._id}`).attr(
                "checked",
                true
              );
              $(`#checkbox_id_${get_event_data.event_type_data._id}`)
                .parent("div")
                .addClass("active-border");
            }
          }, 1000);
        });
    }
  };

  const fetchUserData = async () => {
    await axios.get(`${get_url.local_url}get-user`, header).then((response) => {
      const userData = response.data.data[0];
      if ("undefined" === userData._id) {
        navigate("/Login");
      }

      // if (userData?.event_count <= 0 || userData?.event_count == undefined) {
      //   navigate("/PricingPlans");
      // }
    });
  };
  useEffect(() => {
    fetchEventData();
    fetchEventType();
    fetchUserData();
  }, []);

  return (
    <>
      <Header />
      <div className="footstep-outer">
        <div className="foot-step shadow-lg"> Step 1 of 7</div>
      </div>
      <div>
        <section className="planing_outer">
          <div className="container">
          <Link to="/">
                {" "}
                <i
                  style={{ fontSize: "50px" }}
                  class="fa-sharp fa-solid fa-arrow-left"
                ></i>
              </Link>
            <div className="inner_heading">
              <h4>What type of event are you planning?</h4>
            </div>
            {/* bootstrap tabber */}
            <ul
              className="tabberHost mt-3 nav nav-tabs"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    eventData?.event_type == 1 ? "active" : ""
                  }`}
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  onClick={() => setEvent(1)}
                >
                  Business
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link disabled"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Or
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    eventData?.event_type == 2 ? "active" : ""
                  }`}
                  id="personal-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#personal"
                  type="button"
                  role="tab"
                  aria-controls="personal"
                  aria-selected="false"
                  onClick={() => setEvent(2)}
                >
                  Personal
                </button>
              </li>
            </ul>
            <div className="row">
              {eventTypes.length > 0 &&
                eventTypes.map((item, index) => (
                  <div className="col-lg-4 col-md-4" key={item._id}>
                    <div
                      className="planing_box1 checkList"
                      id={`div_id_${item._id}`}
                      onClick={(e) => {
                        handleRadioButton(item._id, index);
                      }}
                    >
                      <input
                        name="event_type"
                        value={item._id}
                        type="radio"
                        className="get_previos_name"
                        id={`checkbox_id_${item._id}`}
                      />
                      <div>
                        <span className="not-checked-item">{item.name}</span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="Two_Choice mt-3">
                  <div
                    className="row"
                    aria-label="Basic radio toggle button"
                  ></div>
                </div>
              </div>
            </div>
            <div className="btn_outer">
              <p className="item_outer5"></p>
              <button
                className="btn btn-primary submit_button"
                onClick={(e) => {
                  submitForm();
                }}
              >
                Continue{" "}
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Step1;
