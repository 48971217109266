import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import 'react-multiple-select-dropdown-lite/dist/index.css'
import * as _ from 'lodash'; 
import  get_url  from './common/ApiCaller';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'  
import SideBar  from '../events/SideBar';
import Table from 'react-bootstrap/Table';
import EventDetails from '../events/EventDetails';
import $, { error } from 'jquery';
import { toast } from "react-toastify"; 
import axios from "axios";
import {Modal, Button, Col, Row, Form} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ThreeDots } from 'react-loader-spinner'

const TeamManagement = (props) => { 

   window.scrollTo(0, 0);
   
   const [showModal, setShow] = useState(false);
   const [showEditModal, setEditModal] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const handleEditModalClose = () => setEditModal(false);

   const[isEnded, setIsEnded] =useState(false);
   const event_id = localStorage.getItem('event_id');
   const [showTeam, setShowTeam] = useState("");
   const [phone, setPhone]= useState(false);
   const [contactsDrop, setContactsDrop] = useState("");
   const[contactsType, setContactsType] = useState('');
   const[isLoaderDisplay,setIsLoaderDisplay] = useState(false);
   const[userDetails, setUserDetails] = useState({})
   const[teamUser, setTeamUser] = useState({})
   const[activePlan, setActivePlan] = useState({team_member:10000})

   let header = { headers: {
      "Authorization" : `Bearer ${localStorage.getItem('login_token')}`,
      "event_id" : localStorage.getItem('event_id'),
      "user_role" : localStorage.getItem('role')
   }}

   const navigate = useNavigate();
   const eventTypeOf = localStorage.getItem("event_type");
   const userDetail = () => {
      axios.get(`${get_url.local_url}get-user`, header).then((response) => {
          const userData  = response.data.data[0] 
          if ('undefined' === userData) {
              navigate('/Login')
          }
          setUserDetails(userData)
      })
   }

   const handleContact =(e) => {
      setContactsDrop(e.target.value)
   }

   const delete_team = async (team_id) =>{
      setIsLoaderDisplay(true)
      if(window.confirm("Are you sure to delete this team member.")){
         await axios.delete(`${get_url.local_url}delete-team/${team_id}`).then((response) => {
            alert('Team delete successfully.')
            fetchTeam();
         }).catch((error) => console.log(error))
      }else{
         setIsLoaderDisplay(false)
      }
   }
   function random_password() {
      var pass = '';
      var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 'abcdefghijklmnopqrstuvwxyz0123456789@#$';
      for (let i = 1; i <= 8; i++) {
         var char = Math.floor(Math.random() * str.length + 1);
         pass += str.charAt(char)
      }
      return pass+'@Ee1';
   }
   const addTeam = async () => {
      

      let get_first_name = $(`#first_name`).val()
      let get_last_name = $(`#last_name`).val()
      let get_email = $(`#email`).val()
      let get_phone_number = $(`#phone_number`).val()
      let get_company_name = $(`#company_name`).val() 
      let get_contact_type = $(`#contact_type`).val() 

      if ('' === get_first_name) {
         alert ('Please enter your first name.'); return false;
      }
      if ('' === get_last_name) {
         alert ('Please enter your last name.'); return false;
      }
      if ('' === get_email) {
         alert ('Please enter your email.'); return false;
      }
      if ('' === get_phone_number) {
         alert ('Please enter your phone number.'); return false;
      }
      if ('' === get_company_name) {
         alert ('Please enter your company name.'); return false;
      }
      if ('' === get_contact_type) {
         alert ('Please select your conatct type.'); return false;
      }

      let team_data = {
         first_name : get_first_name,
         last_name : get_last_name,
         email : get_email,
         phone_number : get_phone_number,
         company_name : get_company_name,
         contact_type : get_contact_type,
         password :  random_password()
      } 

      setIsLoaderDisplay(true)
      await axios.post(`${get_url.local_url}add-team-data`,team_data,header).then((response) => {
         if(response.data.status == 409){
            alert('Email already in use.');
            setIsLoaderDisplay(false);
             return false;

         }
         $("[aria-label=Close]").trigger({ type: "click" })
         $('.field_for_empty').val('') 
         alert('Team added successfully.');
         fetchTeam();
      }).catch((error) => {
         console.log(error)
         $("[aria-label=Close]").trigger({ type: "click" })
         alert('Some issue. Please add this entry again.')
         setIsLoaderDisplay(false)
      })
   }

   const fetchTeam = async() => {
      setIsLoaderDisplay(true)
      await axios.get(`${get_url.local_url}get-team`,header).then((response) => {
         const get_team_data  = response.data
         setShowTeam(get_team_data)
         setIsLoaderDisplay(false)
      })
   };
   const contactType = async() => {
      await axios.get(`${get_url.local_url}get-contact-type`,header).then((response) => {
         const get_contact_type  = response.data.data
         setContactsType(get_contact_type)
      })
   };

   const getTeamMember = async(teamMemberId) => {
      if ('undefined' === typeof teamMemberId || '' === teamMemberId) {
         return false;
      }

      await axios.get(`${get_url.local_url}team-user/`+teamMemberId, header).then((response) => {
         if (200 != response.data.status) {
            alert(response.data.message);
            return false
         }

         setTeamUser(response.data.data);
         setEditModal(true);
      }) 
   }

   const updateTeamUser = async(teamMemberId) => {
      if ('undefined' === typeof teamMemberId || '' === teamMemberId) {
         return false;
      }


      let get_first_name = $(`#edit_first_name`).val()
      let get_last_name = $(`#edit_last_name`).val()
      let get_email = $(`#edit_email`).val()
      let get_phone_number = $(`#edit_phone_number`).val()
      let get_company_name = $(`#edit_company_name`).val() 
      let get_contact_type = $(`#edit_contact_type`).val() 

      if ('' === get_first_name) {
         alert ('Please enter your first name.'); return false;
      }
      if ('' === get_last_name) {
         alert ('Please enter your last name.'); return false;
      }
      if ('' === get_email) {
         alert ('Please enter your email.'); return false;
      }
      if ('' === get_phone_number) {
         alert ('Please enter your phone number.'); return false;
      }
      if ('' === get_company_name) {
         alert ('Please enter your company name.'); return false;
      }
      if ('' === get_contact_type) {
         alert ('Please select your conatct type.'); return false;
      }

      let team_data = {
         first_name : get_first_name,
         last_name : get_last_name,
         email : get_email,
         phone_number : get_phone_number,
         company_name : get_company_name,
         contact_type : get_contact_type,
      } 

      await axios.post(`${get_url.local_url}update-team-user/`+teamMemberId, team_data, header).then((response) => {
         if (200 != response.data.status) {
            alert(response.data.message);
            return false
         }
         setEditModal(false);
         fetchTeam();
      }) 
   }

   const subscriptionDetail = async() => {
      await axios.get(`${get_url.local_url}get-current-subscription/`, header).then((response) => {
        console.log(response.data.data);
        if (200 == response.data.status) {
            setActivePlan(response.data.data)
        }
      }) 
   }


   useEffect(() => {
      userDetail();
      fetchTeam();
      contactType();
      subscriptionDetail();
   }, [isEnded]);
   return (
      <>
         {isLoaderDisplay? <div className='overlay'><div className="center_dots"><ThreeDots height="80" width="80" radius="9" color="#0d6efd" /></div></div>:''}

         <section className='get_start'>
            <div className='container' >
               <EventDetails/>
            </div >
         </section>
         <section className="check_list">
            <SideBar/>
               <div className="check_right">
                  <div className="row">
                     <div className="col-lg-9 col-md-6">
                        <div className="assign_outer">
                           <h2>Team Management</h2>
                           <p>Use this page to manage your team</p>
                        </div>
                     </div>
                  </div>
                  <hr />
                  <div className="accordion" id="accordionExample">
                     
                     {showTeam.length < activePlan.team_member ? 
                     <div className="Add_more_event">
                     <ButtonToolbar aria-label="Toolbar with button groups">
                     {eventTypeOf == "close_event" ? null : (
                    <Button
                      className="btn btn-primary clickme"
                      onClick={handleShow}
                      onHide={handleClose}
                    >
                      Add Team
                    </Button>
                  )}
                        </ButtonToolbar>
                     </div> : '' }
                     <div className='custom-table-responsive mt-3'>
                        <Table className="table tableC team_management_table" responsive="sm md lg"  >
                           <thead>
                              <tr>
                                 <th scope="col">Action</th>
                                 <th scope="col">Name</th>
                                 <th scope="col">Phone Number</th>
                                 <th scope="col">Email</th>
                                 <th scope="col">Company Name</th>
                                 <th scope="col">Contact Type</th>
                              </tr>
                           </thead>
                           <tbody>
                           <tr key={userDetails._id}>
                              <td>
                              </td>
                              <td>{userDetails.first_name}</td>
                              <td>{userDetails.phone_number}</td>
                              <td>{userDetails.email}</td>
                              <td>N/A</td>
                              <td>N/A</td>
                           </tr> 
                              {showTeam && showTeam?.map((item, index) => {
                                  return(
                                       <>
                                             <tr key={item._id} id={`team_id_${item._id}`}>
                                             <td>
                                                <ul className='action_ul_2'>
                                                   <li><i className="fas fa-trash deleete" onClick={()=>{delete_team(item._id)}}></i></li>
                                                   <li><i class="fas fa-pencil-alt custom_ty66" onClick={()=>{getTeamMember(item._id)}}></i></li>
                                                   
                                                </ul>

                                             </td>
                                             <td>{item.first_name} {item.last_name}</td>
                                             <td>{item.phone_number}</td>
                                             <td>{item.email}</td>
                                             <td>{item.company_name}</td>
                                             <td>{item.contact_type_data.length > 0 ? item.contact_type_data[0].name : 'N/A'}</td>
                                          </tr> 
                                       </>
                                  )})} 
                           </tbody>
                        </Table>
                     </div>
                  </div>
               </div>
         </section>
         <Modal show={showModal} onHide={handleClose} size="lg"  aria-labelledby="contained-modal-title-vcenter"  centered scrollable backdrop="static" keyboard={false}>
               <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                     Add Team
                  </Modal.Title>
               </Modal.Header> 
                  <Container>
                     <Modal.Body scrollable>
                           <Row>
                              <Col xs={12} md={6} className="bottom_space">
                                 <Form.Group controlId="first_name">
                                       <Form.Label>First Name</Form.Label>
                                       <Form.Control type="text" name="first_name" required placeholder="Enter First Name" className="field_for_empty" autoComplete="off"></Form.Control>
                                 </Form.Group>
                              </Col>
                              <Col xs={12} md={6}  className="bottom_space">
                                 <Form.Group controlId="last_name">
                                          <Form.Label>Last Name</Form.Label>
                                          <Form.Control type="text" name="last_name" required placeholder="Enter Last Name" className="field_for_empty" autoComplete="off"></Form.Control>
                                 </Form.Group>
                              </Col>
                           </Row>   
                           <Row>
                              <Col xs={12} md={6} className="bottom_space">
                                 <Form.Group controlId="email">
                                       <Form.Label>Email</Form.Label>
                                       <Form.Control type="email" name="email" required placeholder="Enter Email" className="field_for_empty" autoComplete="off"></Form.Control>
                                 </Form.Group>
                              </Col>
                              <Col xs={12} md={6}  className="bottom_space">
                                 <Form.Group controlId="phone_number">
                                          <Form.Label>Phone Number</Form.Label>
                                          <PhoneInput placeholder="Enter phone number" inputProps={{ name: 'phone_number', required: true, autoFocus: true, id: "phone_number"}} onChange={(e) => setPhone(e)} className="field_for_empty" autoComplete="off"/>
                                 </Form.Group>
                              </Col>
                           </Row>
                           <Row>
                              <Col xs={12} md={6}  className="bottom_space">
                                 <Form.Group controlId="company_name">
                                       <Form.Label>Company Name</Form.Label>
                                       <Form.Control type="text" name="company_name" required placeholder="Enter Company Name" className="field_for_empty" autoComplete="off"></Form.Control>
                                 </Form.Group>
                              </Col>
                              <Col xs={12} md={6}  className="bottom_space">
                                 <Form.Group controlId="contact_type">
                                       <Form.Label>Contact Type</Form.Label>
                                          <Form.Select className='select-option-arrow' onChange={handleContact}  multiple={false} name="contact_type_id" autoComplete="off">
                                             <option defaultValue="" disabled>selected</option>
                                             {contactsType && contactsType.map((contact, index) => {
                                                return  <option key={index} value={contact._id || "" }>{contact.name}</option>;
                                             })};
                                                   
                                          </Form.Select>
                                 </Form.Group>
                              </Col>
                           </Row>
                     </Modal.Body> 
                  <Modal.Footer>
                     <Button variant="danger" onClick={handleClose}>Close</Button>
                     <Button variant="primary" type="button" onClick={()=>{addTeam()}}> Add to Team</Button>
                  </Modal.Footer>
               </Container> 
         </Modal>



         {/* EDIT TEAM MODAL*/}

         <Modal show={showEditModal} onHide={handleEditModalClose} size="lg"  aria-labelledby="contained-modal-title-vcenter"  centered scrollable backdrop="static" keyboard={false}>
               <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                     Edit Team
                  </Modal.Title>
               </Modal.Header> 
                  <Container>
                     <Modal.Body scrollable>
                           <Row>
                              <Col xs={12} md={6} className="bottom_space">
                                 <Form.Group controlId="edit_first_name">
                                       <Form.Label>First Name</Form.Label>
                                       <Form.Control type="text" name="first_name" required placeholder="Enter First Name" className="field_for_empty" autoComplete="off" defaultValue={teamUser.first_name}></Form.Control>
                                 </Form.Group>
                              </Col>
                              <Col xs={12} md={6}  className="bottom_space">
                                 <Form.Group controlId="edit_last_name">
                                          <Form.Label>Last Name</Form.Label>
                                          <Form.Control type="text" name="last_name" required placeholder="Enter Last Name" className="field_for_empty" autoComplete="off" defaultValue={teamUser.last_name}></Form.Control>
                                 </Form.Group>
                              </Col>
                           </Row>   
                           <Row>
                              <Col xs={12} md={6} className="bottom_space">
                                 <Form.Group controlId="edit_email">
                                       <Form.Label>Email</Form.Label>
                                       <Form.Control type="email" name="email" required placeholder="Enter Email" className="field_for_empty" autoComplete="off" defaultValue={teamUser.email}></Form.Control>
                                 </Form.Group>
                              </Col>
                              <Col xs={12} md={6}  className="bottom_space">
                                 <Form.Group controlId="edit_phone_number">
                                          <Form.Label>Phone Number</Form.Label>
                                          <PhoneInput placeholder="Enter phone number" inputProps={{ name: 'phone_number', required: true, autoFocus: true, id: "edit_phone_number"}} onChange={(e) => setPhone(e)} className="field_for_empty" autoComplete="off" defaultValue={teamUser.phone_number} value={teamUser.phone_number}/>
                                 </Form.Group>
                              </Col>
                           </Row>
                           <Row>
                              <Col xs={12} md={6}  className="bottom_space">
                                 <Form.Group controlId="edit_company_name">
                                       <Form.Label>Company Name</Form.Label>
                                       <Form.Control type="text" name="company_name" required placeholder="Enter Company Name" className="field_for_empty" autoComplete="off" defaultValue={teamUser.company_name}></Form.Control>
                                 </Form.Group>
                              </Col>
                              <Col xs={12} md={6}  className="bottom_space">
                                 <Form.Group controlId="edit_contact_type">
                                       <Form.Label>Contact Type</Form.Label>
                                          <Form.Select className='select-option-arrow' onChange={handleContact}  multiple={false} name="contact_type_id" autoComplete="off" defaultValue={teamUser.contact_type_id}>
                                             <option defaultValue="" disabled>selected</option>
                                             {contactsType && contactsType.map((contact, index) => {
                                                return  <option key={index} value={contact._id || "" } >{contact.name}</option>;
                                             })};
                                                   
                                          </Form.Select>
                                 </Form.Group>
                              </Col>
                           </Row>
                     </Modal.Body> 
                  <Modal.Footer>
                     <Button variant="danger" onClick={handleEditModalClose}>Close</Button>
                     <Button variant="primary" type="button" onClick={()=>{updateTeamUser(teamUser._id)}}> Update Team</Button>
                  </Modal.Footer>
               </Container> 
         </Modal>
      </>
   )

}
export default TeamManagement
