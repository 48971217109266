import React, { Component } from 'react'; 

function SuperAdminRoute({ children }){  
    const token = localStorage.getItem('tokens')
    const role = localStorage.getItem('role')
    if(token && role === 'superAdmin'){ 
        return children;
    }else{   
        window.location.href = "/Signin";
    }
}
export default SuperAdminRoute;